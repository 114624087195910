import React, { useState, useEffect } from "react";
import {
	Form,
	Input,
	Button,
	Select,
	Checkbox,
	InputNumber,
	Row,
	Col,
	Typography,
} from "antd";
import "./firstTimeProfile.css"; // Ensure this CSS file exists for styling

import {
	industryOptions,
	skillOptions,
	startingTimeOptions,
} from "../../resource/resource";
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

function FirstTimeProfile() {
	const [formData, setFormData] = useState({
		salaryMinimum: 0,
		salaryMaximum: 0,
		salaryCurrency: "",
		startingTimeLine: "",
		industries: [],
		experience: 0,
		skills: [],
		coverLetter: "",
		resume: "",
		companyBlackList: "",
		isActive: false,
	});

	// Update form data when specific fields change
	const handleFormChange = (changedValues, allValues) => {
		// For fields like isActive, convert boolean to 1 or 0 before submitting
		if (changedValues.hasOwnProperty("isActive")) {
			allValues.isActive = changedValues.isActive ? 1 : 0;
		}
		setFormData(allValues);
	};

	const handleSubmit = async () => {
		// Before submitting, convert arrays to comma-separated strings
		const submissionData = {
			...formData,
			industries: formData.industries.join(","),
			skills: formData.skills.join(","),
		};
		const token = localStorage.getItem("idToken"); // Get the stored token
		console.log(submissionData);
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_ENDPOINT}/user/profile/create`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: token, // Use the token directly as per your setup
					},
					body: JSON.stringify(submissionData),
				}
			);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();

			window.location.href = "/dashboard"; // Redirect on success
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const fetchUserData = async () => {
		const token = localStorage.getItem("idToken"); // Get the stored token

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_ENDPOINT}/user/profile`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: token,
					},
				}
			);

			if (response.status === 401) {
				window.location.href = "/login";
			}

			if (!response.success) {
			}

			const user = await response.json();

			if (
				typeof user.data.salaryMinimum != "undefined" ||
				typeof user.data.salaryMaximum != "undefined" ||
				typeof user.data.salaryCurrency != "undefined" ||
				typeof user.data.startingTimeLine != "undefined" ||
				(typeof user.data.industries != "undefined" &&
					user.data.industries.length > 0) ||
				typeof user.data.experience != "undefined" ||
				(typeof user.data.skills != "undefined" &&
					user.data.skills.length > 0) ||
				typeof user.data.coverLetter != "undefined" ||
				typeof user.data.resume != "undefined" ||
				typeof user.data.companyBlackList != "undefined"
			) {
				window.location.href = "/dashboard";
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	useEffect(() => {
		fetchUserData();
	}, []);

	return (
		<div className="profile-container">
			<Title level={2}>First Time Profile Setup</Title>
			<Form
				layout="vertical"
				onFinish={handleSubmit}
				onValuesChange={handleFormChange}
				initialValues={formData}
			>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="salaryMinimum"
							label="Alin palkkatoive"
							rules={[
								{ required: true, message: "Please input the salary minimum!" },
							]}
						>
							<InputNumber min={0} step={100} style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="salaryMaximum"
							label="Ylin palkkatoive"
							rules={[
								{ required: true, message: "Please input the salary maximum!" },
							]}
						>
							<InputNumber min={0} step={100} style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="salaryCurrency"
							label="Palkan valuutta"
							rules={[
								{
									required: true,
									message: "Please select the salary currency!",
								},
							]}
						>
							<Select placeholder="Select a currency">
								<Option value="EUR">EUR</Option>
								<Option value="USD">USD</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="startingTimeLine"
							label="Kuinka nopeasti voit aloittaa?"
							rules={[
								{
									required: true,
									message: "Please select the starting timeline!",
								},
							]}
						>
							<Select placeholder="Select a timeline">
								{startingTimeOptions.map((time) => (
									<Option key={time} value={time}>
										{time}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="industries"
							label="Millä aloilla olet työskennellyt?"
							rules={[{ required: true, message: "Please select industries!" }]}
						>
							<Select
								mode="multiple"
								allowClear
								placeholder="Select industries"
								options={industryOptions.map((industry) => ({
									value: industry,
								}))}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="experience"
							label="Keskimääräinen kokemus KUUKAUSINA relevanteilla aloilla?"
							rules={[
								{
									required: true,
									message: "Please input your experience in years!",
								},
							]}
						>
							<InputNumber min={0} style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="skills"
							label="Mitä taitoja sinulla on?"
							rules={[
								{ required: true, message: "Please select your skills!" },
							]}
						>
							<Select
								mode="multiple"
								allowClear
								placeholder="Select skills"
								options={skillOptions.map((skill) => ({ value: skill }))}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="coverLetter"
							label="Kirjoita vapaamuotoinen esittely itsestäsisi ja osaamisestasi."
							rules={[
								{ required: true, message: "Please input your cover letter!" },
							]}
						>
							<TextArea rows={4} />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="resume"
							label="Resume URL"
							rules={[
								{ required: true, message: "Please input your resume URL!" },
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="companyBlackList"
							label="Yritykset joille et halua näkyä"
						>
							<Input placeholder="Company Blacklist (comma-separated)" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="isActive"
							valuePropName="checked"
							label="Is Active"
						>
							<Checkbox />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
					</Col>
				</Row>
			</Form>
		</div>
	);
}

export default FirstTimeProfile;
