import React, { useState, useEffect } from 'react';
import { List, Typography, Collapse, Modal, Button } from 'antd';

const { Panel } = Collapse;

function UserSearchList() {
	const [searchStatus, setSearchStatus] = useState({
		usedRequests: 0,
		totalRequests: 0,
		resetTime: null,
	});
    const [searchResults, setSearchResults] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [visible, setVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

	// Function to fetch search status
	const fetchSearchStatus = async () => {
		setLoadingStatus(true);
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_ENDPOINT}/search/companyStatus`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("idToken"),
					},
				}
			);
			// if (!response.ok) throw new Error("Network response was not ok");
			const data = await response.json();

			setSearchStatus({
				usedRequests: data.usedJobSearchAllocation,
				totalRequests: data.maxJobSearchAllocation,
				resetTime: data.resetDateTime,
			});
		} catch (error) {
			console.error("Error fetching search status:", error);
		} finally {
			setLoadingStatus(false);
		}
	};

	// Function to handle search
	const handleSearch = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_ENDPOINT}/search/candidates`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("idToken"),
					},
				}
			);
			if (!response.ok) throw new Error("Network response was not ok");
			const data = await response.json();
			console.log(data); // Log the data for debugging
			setSearchResults(data.jobPost); // Assuming the response contains a jobPost array
			setSearchStatus((prevState) => ({
				...prevState,
				usedRequests: data.usedJobPosts,
				totalRequests: data.maxJobPosts,
				resetTime: data.resetDateTime,
			}));
		} catch (error) {
			console.error("Error performing search:", error);
		}
	};

	// Calculate time until reset
	const calculateTimeUntilReset = () => {
		if (!searchStatus.resetTime) return "";
		const resetDate = new Date(searchStatus.resetTime);
		const now = new Date();
		const difference = resetDate.getTime() - now.getTime();
		if (difference < 0) return "Resetting...";
		const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
		const minutes = Math.floor((difference / (1000 * 60)) % 60);
		return `${hours} hours and ${minutes} minutes until reset.`;
	};

    const showModal = (user) => {
        setSelectedUser(user);
        setVisible(true);
      };

      const handleOk = e => {
        console.log(e);
        setVisible(false);
      };

      const handleCancel = e => {
        console.log(e);
        setVisible(false);
      };

      useEffect(() => {
        fetchSearchStatus();
      }, []);

	if (loadingStatus) return <div>Loading search status...</div>;

    return (
        <div>
          <h2>User Search List</h2>
          <div>Used Requests: {searchStatus.usedRequests}</div>
          <div>Total Requests: {searchStatus.totalRequests}</div>
          {searchStatus.usedRequests >= searchStatus.totalRequests && (
            <div>{calculateTimeUntilReset()}</div>
          )}
          <button onClick={handleSearch}>Search</button>
          {/* Display search results */}
          {searchResults.length > 0 && (
            <div>
              <h3>Search Results</h3>
              <Collapse accordion>
                {searchResults.map((result, index) => (
                  <Panel header={result.industries} key={index}>
                    <List.Item>
                      <List.Item.Meta
                        title={<Typography.Text strong>{result.industries}</Typography.Text>}
                        description={
                          <>
                            <Typography.Text type="secondary">  Skills: {Array.isArray(result.skills) ? result.skills.slice(0, 4).join(', ') : 'No skills listed'}</Typography.Text><br/>
                            <Typography.Text type="secondary">Salary Expectation: {result.salaryMinimum} - {result.salaryMaximum}</Typography.Text><br/>
                            <Typography.Text type="secondary">Experience: {result.experience}</Typography.Text><br/>
                            <Button type="primary" onClick={() => showModal(result)}>View More</Button>
                          </>
                        }
                      />
                    </List.Item>
                  </Panel>
                ))}
              </Collapse>
              <Modal
                title="User Details"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                {selectedUser && (
                  <>
                    <p>City: {selectedUser.city}</p>
                    <p>Country: {selectedUser.country}</p>
                    <p>Email: {selectedUser.email}</p>
                    <p>First Name: {selectedUser.firstName}</p>
                    <p>Last Name: {selectedUser.lastName}</p>
                    <p>Phone Number: {selectedUser.phoneNumber}</p>
                    <p>User ID: {selectedUser.userId}</p>
                  </>
                )}
              </Modal>
            </div>
          )}
        </div>
	);
};

export default UserSearchList;
