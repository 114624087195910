import React, { useState } from "react";
import { Button, Input, Form, Select, Row, Col } from "antd";

import "./register.css"; // Import custom CSS for additional styling

const { Option } = Select;
const countries = [{ label: "Suomi", value: "Suomi" }];

function Register() {
	const [formData, setFormData] = useState({
		email: "",
		password: "",
		confirmPassword: "",
		firstname: "",
		lastname: "",
		country: "",
		city: "",
		phone: "",
	});

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSelectChange = (value, name) => {
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		if (formData.password !== formData.confirmPassword) {
			alert("Passwords do not match!");
			return;
		}

		// Register the user
		try {
			const registerationResponse = await fetch(
				process.env.REACT_APP_API_ENDPOINT + "/auth/register",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						password: formData.password,
						email: formData.email,
						firstName: formData.firstname,
						lastName: formData.lastname,
						phoneNumber: formData.phone,
						country: formData.country,
						city: formData.city,
					}),
				}
			);

			if (!registerationResponse.ok) {
				throw new Error(`HTTP error! status: ${registerationResponse.status}`);
			}

			// Log the user in
			const loginResponse = await fetch(
				process.env.REACT_APP_API_ENDPOINT + "/auth/login",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						email: formData.email,
						password: formData.password,
					}),
				}
			);

			if (!loginResponse.ok) {
				throw new Error(`HTTP error! status: ${loginResponse.status}`);
			}

			const loginData = await loginResponse.json();

			localStorage.clear();

			localStorage.setItem("accessToken", loginData.accessToken);
			localStorage.setItem("refreshToken", loginData.refreshToken);
			localStorage.setItem("idToken", loginData.idToken);
			localStorage.setItem("userEmail", loginData.userEmail);

			window.location.href = "/firstTimeProfile";
		} catch (error) {
			alert("Registration failed!");
		}
	};

	return (
		<div className="register-background">
			<div className="register-container">
				<Form onFinish={handleSubmit} className="register-form">
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="firstname"
								rules={[
									{ required: true, message: "Please input your First Name!" },
								]}
							>
								<Input
									placeholder="Etunimi"
									name="firstname"
									value={formData.firstname}
									onChange={handleChange}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="lastname"
								rules={[
									{ required: true, message: "Please input your Last Name!" },
								]}
							>
								<Input
									placeholder="Sukunimi"
									name="lastname"
									value={formData.lastname}
									onChange={handleChange}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="country"
								rules={[
									{ required: true, message: "Please select your Country!" },
								]}
							>
								<Select
									showSearch
									placeholder="Valitse maa"
									optionFilterProp="children"
									onChange={(value) => handleSelectChange(value, "country")}
									filterOption={(input, option) =>
										option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									notFoundContent={null} // Add this line
								>
									{countries.map((country) => (
										<Option key={country.value} value={country.value}>
											{country.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="city"
								rules={[{ required: true, message: "Please input your City!" }]}
							>
								<Input
									placeholder="Kaupunki"
									name="city"
									value={formData.city}
									onChange={handleChange}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						name="phone"
						rules={[
							{ required: true, message: "Please input your Phone Number!" },
						]}
					>
						<Input
							placeholder="Puhelinnumero"
							name="phone"
							value={formData.phone}
							onChange={handleChange}
						/>
					</Form.Item>
					<Form.Item
						name="email"
						rules={[{ required: true, message: "Please input your Email!" }]}
					>
						<Input
							type="email"
							placeholder="Sähköposti"
							name="email"
							value={formData.email}
							onChange={handleChange}
						/>
					</Form.Item>
					<Form.Item
						name="password"
						rules={[{ required: true, message: "Please input your Password!" }]}
					>
						<Input.Password
							placeholder="Salasana"
							name="password"
							value={formData.password}
							onChange={handleChange}
						/>
					</Form.Item>
					<Form.Item
						name="confirmPassword"
						rules={[
							{ required: true, message: "Please confirm your Password!" },
						]}
					>
						<Input.Password
							placeholder="Vahvista salasana"
							name="confirmPassword"
							value={formData.confirmPassword}
							onChange={handleChange}
						/>
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							Rekisteröidy
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
}

export default Register;
