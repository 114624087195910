import "./App.css";
import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
} from "react-router-dom";
import "antd";

// Uset
import Login from "./components/login/login";
import Register from "./components/register/register";

import Dashboard from "./components/dashboard/dashboard";
import FisrtTimeProfile from "./components/profile/firstTimeProfile";
import MyProfile from "./components/profile/myProfile";

// Corporation
import CorpRegister from "./components/corp/register/register";
import CorpLogin from "./components/corp/login/login";
import CorpJoinCompany from "./components/corp/company/joinCompany";
import CorpCreateCompany from "./components/corp/company/createCompany";
import CorpBuyService from "./components/corp/company/buyService";

import CorpCreateCompanyForm from "./components/corp/company/createCompanyForm";

import CorpDashboard from "./components/corp/dashboard/dashboard";

import AllJobListings from './components/corp/jobListing/allJobListings';
import CreateJobListing from "./components/corp/jobListing/createJobListing";

import UserSearchList from "./components/corp/users/userSearchList";


import UserAuthHeader from "./components/Header/userAuthHeader";
import UserHeader from "./components/Header/userHeader";
import CorpHeader from "./components/Header/corpHeader";
import CorpAuthHeader from "./components/Header/corpAuthHeader";

import LandingPage from "./components/prelaunch/landingPage";


function App() {
	return (
		<Router>
			<Routes>

                <Route path="/" element={<><LandingPage/></>} />
  {/*               <Route path="/login" element={<><UserAuthHeader /><Login /></>} />
                <Route path="/register" element={<><UserAuthHeader /><Register /></>} />
                <Route path="/corp/Register" element={<><CorpAuthHeader/> <CorpRegister /></>} />
                <Route path="/corp/Login" element={<><CorpAuthHeader/> <CorpLogin /> </>} />

                <Route path="/firstTimeProfile" element={<><UserHeader /><FisrtTimeProfile /></>} />
                <Route path="/dashboard" element={<><UserHeader /><Dashboard /></>} />
                <Route path="/myProfile" element={<><UserHeader /><MyProfile /></>} />

                <Route path="/corp/dashboard" element={<><CorpHeader/><CorpDashboard /></>} />

                <Route path="/corp/jobListing/createJobListing" element={<><CorpHeader/><CreateJobListing /></>} />
                <Route path="/corp/jobListing/allJobListings" element={<><CorpHeader/><AllJobListings /></>} />

                <Route path="/corp/users/userSearchList" element={<><CorpHeader/><UserSearchList /></>} />

                <Route path="/corp/joinCompany" element={<><CorpHeader/><CorpJoinCompany /> </>} />
                <Route path="/corp/createCompany" element={<><CorpHeader/><CorpCreateCompany /></>} />
                <Route path="/corp/buyService" element={<><CorpHeader/><CorpBuyService /> </>} />



                <Route path="/creareee" element={<CorpCreateCompanyForm />} /> */}
			</Routes>
		</Router>
	);
}

export default App;
