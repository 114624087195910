import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./buyService.css"; // Import a CSS file for styling

// Load the Stripe script with your public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// Mock product data
const products = [
  { id: "prod_PaJr5cP6qAfMla", name: "Corporate", price: 1000, type: "corporate" },
  { id: "prod_PaJrHGULB9Ad7J", name: "Premium", price: 500, type: "premium" },
  { id: "prod_PaJr4NiwW1AnhN", name: "Free", price: 0, type: "free" },
  { id: "prod_PaJbHDQ38Z7qJw", name: "Medium", price: 250, type: "medium" },
];

// CheckoutForm component
const CheckoutForm = ({ selectedProduct }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/stripe/intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("idToken"),
        },
        body: JSON.stringify({ companyLevel: selectedProduct.id, amount: selectedProduct.price, currency: "eur" }),
      });

      const { clientSecret } = await response.json();

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (result.error) {
        console.log(result.error.message);
      } else {
        if (result.paymentIntent.status === "succeeded") {
            window.location.href = '/corp/dashboard'; // replace '/success' with your success page URL
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <CardElement options={{ style: { base: { fontSize: '16px', color: '#424770', '::placeholder': { color: '#aab7c4' } } } }} />
      <button type="submit" disabled={!stripe} className="submit-button">
        Pay €{selectedProduct.price} for {selectedProduct.name}
      </button>
    </form>
  );
};

// Main component
function CorpBuyService() {
  const [selectedProduct, setSelectedProduct] = useState(products[0]); // Default to the first product

  return (
    <div className="corp-buy-service">
      <h1>Create Company</h1>
      <div className="product-selection">
        {products.map((product) => (
          <div key={product.id} onClick={() => setSelectedProduct(product)} className="product-item">
            {product.name} - €{product.price}
          </div>
        ))}
      </div>
      {selectedProduct && (
        <Elements stripe={stripePromise}>
          <CheckoutForm selectedProduct={selectedProduct} />
        </Elements>
      )}
    </div>
  );
}

export default CorpBuyService;
