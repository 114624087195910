import React, { useState, useEffect } from "react";
import { Input, Button, Form, Divider, Select, Modal } from "antd";
import {
	PhoneOutlined,
	EnvironmentOutlined,
	CalendarOutlined,
	FileTextOutlined,
	DollarOutlined,
	LoadingOutlined,
	CloseOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";
import "./myProfile.css";

import {
	industryOptions,
	skillOptions,
	startingTimeOptions,
} from "../../resource/resource";

const { TextArea } = Input;
const { Option } = Select;

function MyProfile() {
	const [form] = Form.useForm();
	const [userData, setUserData] = useState({});
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const token = localStorage.getItem("idToken");
			const response = await fetch(
				process.env.REACT_APP_API_ENDPOINT + "/user/profile",
				{
					method: "GET",
					headers: {
						Authorization: token,
					},
				}
			);

			if (response.status === 401) {
				window.location.href = "/login";
			}

			if (!response.success) {
			}

			const data = await response.json();

            if (typeof data.data.industries === 'string') {
                data.data.industries = data.data.industries.split(',');
            }
            if (typeof data.data.skills === 'string') {
                data.data.skills = data.data.skills.split(',');
            }

            console.log(data.data);

			setUserData(data.data);



			setIsLoading(false);
		} catch (error) {
			setError(error.message);
			setIsLoading(false);
		}
	};

	const toggleEdit = () => {
		setIsEditing(!isEditing);
		if (!isEditing) {
			form.setFieldsValue(userData);
		}
	};

	const showDeleteConfirm = () => {
		Modal.confirm({
			title: "Are you sure delete your account?",
			icon: <ExclamationCircleOutlined />,
			content: "This action cannot be undone.",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk() {
				console.log("OK");
				// Handle the account deletion here
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const handleResetPassword = () => {
		// Handle the password reset here
		console.log("Reset password clicked");
	};

	const saveProfile = async () => {
		try {
			const token = localStorage.getItem("idToken");
            const values = form.getFieldsValue();

            // Make a copy of the values
            const updatedValues = { ...values };

            // Convert the industries array into a string
            if (Array.isArray(updatedValues.industries)) {
                updatedValues.industries = updatedValues.industries.join(',');
            }
            if (Array.isArray(updatedValues.skills)) {
                updatedValues.skills = updatedValues.skills.join(',');
            }

			const response = await fetch(
				`${process.env.REACT_APP_API_ENDPOINT}/user/profile/update`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: token, // Use the token directly as per your setup
					},
					body: JSON.stringify(updatedValues),
				}
			);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			console.log(data);
			setIsEditing(false);
		} catch (error) {
			console.log("Validation failed:", error);
		}
	};

	if (isLoading) {
		return (
			<div className="loading-container">
				<LoadingOutlined style={{ fontSize: 24 }} />
				<p>Loading...</p>
			</div>
		);
	}

	if (error) {
		return <div>Error: {error}</div>;
	}

	return (
		<div className="profile-container">
			<h1>Profiilitiedot</h1>
			<div className="profile-info-container">
				<div className="user-info">
					<p>
						<strong>Sähköposti:</strong> {userData.email}
					</p>
					<p>
						<strong>Etunimi:</strong> {userData.firstName}
					</p>
					<p>
						<strong>Sukunimi:</strong> {userData.lastName}
					</p>
				</div>
				<div className="action-buttons">
					<Button onClick={handleResetPassword}>Resetoi Salasana</Button>
					<Button danger onClick={showDeleteConfirm}>
						Poista Käyttäjä
					</Button>{" "}
				</div>
			</div>
			<Form
				form={form}
				layout="vertical"
				onFinish={saveProfile}
				initialValues={userData}
			>
				<Divider orientation="left">Henkilökohtaiset Tiedot</Divider>
				<Form.Item name="phoneNumber" label="Puhelin Numero">
					<Input prefix={<PhoneOutlined />} disabled={!isEditing} />
				</Form.Item>
				<Form.Item name="country" label="Maa">
					<Input prefix={<EnvironmentOutlined />} disabled={!isEditing} />
				</Form.Item>
				<Form.Item name="city" label="Kaupunki">
					<Input prefix={<EnvironmentOutlined />} disabled={!isEditing} />
				</Form.Item>
				<Divider orientation="left">Job Preference</Divider>
				<Form.Item name="industries" label="Alat">
					<Select
						disabled={!isEditing}
						mode="multiple"
						allowClear
						placeholder="Select industries"
						options={industryOptions.map((industries) => ({value: industries}))}
					/>
				</Form.Item>
				<Form.Item name="skills" label="Taidot">
					<Select
						disabled={!isEditing}
						mode="multiple"
						allowClear
						placeholder="Select skills"
						options={skillOptions.map((skill) => ({ value: skill }))}
					/>
				</Form.Item>
				<Form.Item name="experience" label="Kokemus (Kuukaudet)">
					<Input
						prefix={<CalendarOutlined />}
						type="number"
						disabled={!isEditing}
					/>
				</Form.Item>
				<Form.Item label="Palkka toiveet" style={{ marginBottom: 0 }}>
					<Form.Item
						name="salaryMinimum"
						style={{ display: "inline-block", width: "calc(33.3% - 8px)" }}
					>
						<Input
							prefix={<DollarOutlined />}
							type="number"
							disabled={!isEditing}
						/>
					</Form.Item>
					<span
						style={{
							display: "inline-block",
							width: "16px",
							textAlign: "center",
						}}
					>
						-
					</span>
					<Form.Item
						name="salaryMaximum"
						style={{ display: "inline-block", width: "calc(33.3% - 8px)" }}
						rules={[
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue("salaryMinimum") < value) {
										return Promise.resolve();
									}
									return Promise.reject(
										new Error("Max salary must be greater than min salary")
									);
								},
							}),
						]}
					>
						<Input
							prefix={<DollarOutlined />}
							type="number"
							disabled={!isEditing}
							placeholder="Maximum"
						/>
					</Form.Item>
					<Form.Item
						name="salaryCurrency"
						style={{
							display: "inline-block",
							width: "calc(33.3% - 8px)",
							marginTop: "-30px",
							marginLeft: "2px",
						}}
						label="Valuutta"
					>
						<Select placeholder="Select a currency" disabled={!isEditing}>
							<Option value="EUR">EUR</Option>
							<Option value="USD">USD</Option>
						</Select>
					</Form.Item>
				</Form.Item>
				<Form.Item
					name="startingTimeLine"
					label="Kuinka Nopeasti Voit Aloittaa"
				>
					<Input prefix={<CalendarOutlined />} disabled={!isEditing} />
				</Form.Item>
				<Form.Item name="companyBlackList" label="Estetyt yritykset">
					<Input prefix={<CloseOutlined />} disabled={!isEditing} />
				</Form.Item>
				<Divider orientation="left">Lisätietoja</Divider>
				<Form.Item name="coverLetter" label="Kirjoita itsestäsi vapaasti">
					<TextArea
						autoSize={{ minRows: 3 }}
						prefix={<FileTextOutlined />}
						disabled={!isEditing}
					/>
				</Form.Item>
				<Form.Item name="resume" label="CV">
					<Input prefix={<FileTextOutlined />} disabled={!isEditing} />
				</Form.Item>
				{isEditing ? (
					<div className="edit-buttons">
						<Button type="primary" htmlType="submit">
							Save
						</Button>
						<Button onClick={toggleEdit}>Cancel</Button>
					</div>
				) : (
					<Button onClick={toggleEdit} block>
						Edit
					</Button>
				)}
			</Form>
		</div>
	);
}

export default MyProfile;
