import "./landingPage.css";
import certificateImage from "../../resource/undraw_interview_re_e5jn.svg";
import secondaryImage from "../../resource/undraw_certificate_re_yadi.svg";
import React, { useRef, useState } from "react";
import { Row, Col, Button, Card, Collapse, message, Input, Switch } from "antd";

const { Panel } = Collapse;

function LandingPage() {
	// Scroll to Ref
	const tyontekijalleRef = useRef(null);
	const tyonantajalleRef = useRef(null);
	const faqRef = useRef(null);
	const hinnastoRef = useRef(null);
	const otaYhteyttaRef = useRef(null);

	const scrollToRef = (ref) => {
		ref.current.scrollIntoView({ behavior: "smooth" });
	};

	// email list
	const [email, setEmail] = useState("");
	const [isJobSeeker, setIsJobSeeker] = useState(false); // Set default as false for "Työnantaja"
	const [additionalInfo, setAdditionalInfo] = useState("");

	const handleJoinMailingList = () => {
		// Here you can add your logic to submit the email to your backend or mailing list service
		// For now, let's just display a success message
		message.success(
			`You've successfully joined the mailing list with email: ${email}`
		);
		// Clear the input field after successful submission
		setEmail("");
	};

	return (
		<div className="landing-page">
			{/* Header Section */}
			<header className="dark-blue-header">
				<Row justify="space-between" align="middle">
					<Col>
						<h1 className="company-name">MetaWork</h1>
					</Col>
					<Col>
						<nav className="header-nav">
							<Button type="link" onClick={() => scrollToRef(tyontekijalleRef)}>
								Työnhakijalle
							</Button>
							<Button type="link" onClick={() => scrollToRef(tyonantajalleRef)}>
								Työnantajalle
							</Button>
							<Button type="link" onClick={() => scrollToRef(faqRef)}>
								FAQ
							</Button>
							<Button type="link" onClick={() => scrollToRef(hinnastoRef)}>
								Hinnasto
							</Button>
							<Button type="link" onClick={() => scrollToRef(otaYhteyttaRef)}>
								Ota yhteyttä
							</Button>
						</nav>
					</Col>
				</Row>
			</header>

			{/* Quick Intro Section */}
			<section className="quick-intro">
				<Row justify="center">
					<Col span={24}>
						<Row>
							<Col span={12} className="intro-text">
								<h1>Ammattilaiset sekä työnantajat yhdestä osoitteesta</h1>
								<p>
									Liity mukaan Metaworkkiin, missä työntekijät ja työnantajat
									kohtaavat yhden napsautuksen päässä! Tervetuloa luomaan
									menestystarinoita yhdessä!
								</p>
								<Button
									type="primary"
									onClick={() => scrollToRef(otaYhteyttaRef)}
								>
									Liity postituslistalle &#8594;
								</Button>
							</Col>
							<Col span={3}></Col>
							<Col span={9}>
								<img
									className="certificate-image"
									src={certificateImage}
									alt="Certificate"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</section>

			{/* Sections for Työnhakijalle, Työnantajalle, FAQ, Hinnasto, and Ota yhteyttä */}
			<section ref={tyontekijalleRef} id="tyontekijalle" className="section">
				<Row justify="center">
					<Col span={24}>
						<h2>Miten löydät unelmiesi työpaikan?</h2>
					</Col>
					<Col span={8}>
						<Card title="1. Luo ilmainen profiili" bordered={false}>
							<p>
								Luo ilmainen anonyymi profiili joka näytetään sadoille
								työpaikoille. Ilmoita työkokemuksesi, koulutuksesi ja
								osaamisesi.
							</p>
						</Card>
					</Col>
					<Col span={8}>
						<Card title="2. Valitse mieleisesi työnantaja" bordered={false}>
							<p>
								Odota työnantajien ottavan sinuun yhteyttä. Valitse mieleisesi
								työnantaja sekä työtarjous.
							</p>
						</Card>
					</Col>
					<Col span={8}>
						<Card title="3. Anna yhteystietosi" bordered={false}>
							<p>
								Kun olet löytänyt unelmiesi työpaikan, anna yhteystietosi
								työnantajalle sekä nauti uudesta työpaikastasi!
							</p>
						</Card>
					</Col>
				</Row>
			</section>

			{/* Quick Intro Section */}
			<section className="quick-intro-secondary">
				<Row justify="center">
					<Col span={24}>
						<Row>
							<Col span={9}>
								<img
									className="certificate-image"
									src={secondaryImage}
									alt="Certificate"
								/>
							</Col>
							<Col span={3}></Col>
							<Col span={12} className="intro-text-secondary">
								<h1>Täysin ilmainen työnhakijalle!</h1>
								<p>
									Keskiketty alusta unelmiesi työpaikan löytämiseen. Liity
									mukaan MetaWorkkiin ja löydä unelmiesi työpaikka!
								</p>
								<Button
									type="primary"
									onClick={() => scrollToRef(otaYhteyttaRef)}
								>
									Liity postituslistalle &#8594;
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</section>

			<section
				ref={tyonantajalleRef}
				id="tyonantajalle-point"
				className="section"
			>
				<Row justify="center">
					<Col span={18}>
						<h2>Miten yritykseni hyötyy MetaWorkistä?</h2>
					</Col>
					<Col span={8}>
						<Card title="Satoja osaajia yhden haun päässä" bordered={false}>
							<p>
								Löydä satoja osaajia juuri yrityksesi tarpeisiin yhdellä haulla!{" "}
							</p>
						</Card>
					</Col>
					<Col span={8}>
						<Card
							title="Anna tekoälyn löytää unelmiesi osaajan"
							bordered={false}
						>
							<p>
								Tekoäly etsii sinulle parhaat osaajat työnhakuilmoituksen
								perusteella! Valitse työnkuvaan räätälöidystä listasta unemiesi
								osaaja.
							</p>
						</Card>
					</Col>
					<Col span={8}>
						<Card title="Leikkaa rekrytöintikuluja" bordered={false}>
							<p>
								Unohda kalliit työnhakuilmoitukset ja palkkaa osaaja suoraan
								meidän listasta! Meiltä löydät alan ammattilaiset ilman turhaa
								sählinkiä."
							</p>
						</Card>
					</Col>
				</Row>
			</section>

			{/* FAQ Sections for Employers and Employees */}
			<div ref={faqRef} id="faq-container">
				<Row justify="center" className="FAQ">
					<Col span={10}>
						<section id="tyonantajalle" className="section">
							<h2>Työnantajalle</h2>
							<Collapse accordion>
								<Panel header="Mikä on tekoäly työnhaku?" key="employer-1">
									<p>
										Tekoäly työnhaku on MetaWorking sisäisesti kehitetty
										työnhakualgoritmi, joka etsii sinulle parhaat osaajat
										työnhakuilmoituksen perusteella. Käytössäsi on meidän täysi
										työnhakija lista.
									</p>
								</Panel>
								<Panel
									header="Miten löydän täydellisen työntekijän?"
									key="employer-2"
								>
									<p>
										Hyödynnä MetaWorkin monipuolisia suodattimia, kuten kokemus,
										koulutus ja osaaminen, rajataksesti kaikki työnhakijat ja
										löydät helposti sopivimman ehdokkaan.
									</p>
								</Panel>
								<Panel header="Miten otan yhteyttä työnhakijaan?" key="employer-3">
									<p>Kun olet löytänyt täydellisen työntekijän, voit lähettää lähettää sisäisen viestin ammattilaiselle sekä sopia haastatteun.</p>
								</Panel>
								{/* Add more Panel components for employers as needed */}
							</Collapse>
						</section>
					</Col>
					<Col span={10}>
						<section id="tyontekijalle" className="section">
							<h2>Työnhakijalle</h2>
							<Collapse accordion>
								<Panel
									header="Näkevätkö työnantajat yhteystietoni?"
									key="employee-1"
								>
									<p>
										Ei. MetaWorking arvot perustuvat yksityisyyteen sekä
										tasa-arvoon. Yrityksen eivät pääse sinun yhteystietoihin
										ennen kuin olet itse antanut luvan kyseiselle yritykselle.
										Yritykset näkevät vain työkokemuksesi, koulutuksesi sekä
										osaamisesi.
									</p>
								</Panel>
								<Panel header="Paljonko tämä maksaa minulle?" key="employee-2">
									<p>Ei yhtään mitään!</p>
								</Panel>
								<Panel
									header="Kuinka kauan minulla kestää luoda profiili?"
									key="employer-3"
								>
									<p>
										Voit luoda profiilsi alle 5 minuutissa jonka jälkeen olet
										näkyvillä sadoille työnantajille!
									</p>
								</Panel>
								{/* Add more Panel components for employees as needed */}
							</Collapse>
						</section>
					</Col>
				</Row>
			</div>

			<section ref={hinnastoRef} id="hinnasto" className="section">
				<Row justify="center" gutter={[16, 16]}>
					<Col span={8}>
						<Card title="Basic" className="pricing-card">
							<p className="price">€0.00/kuukaudessa</p>
							<ul>
								<li>1 Käyttäjä</li>
								<li>1 Työilmoitus kerralla</li>
								<li>3 Työntekijäkannan hakua päivässä</li>
                                <li></li>
							</ul>

                            <p id="PricingCardDescription"> Tämä paketti on suunniteltu erityisesti uusille pienille yrityksille, tarjoten kattavan ratkaisun rekrytointiprosessin tehokkaaseen aloittamiseen ja hallinnointiin. Sen avulla voitte löytää oikeat henkilöt avoimiin tehtäviinne ja kasvattaa liiketoimintaanne menestyksekkäästi.</p>
						</Card>
					</Col>
					<Col span={8}>
						<Card title="Plus" className="pricing-card">
							<p className="price">€9.99/kuukaudessa</p>
							<ul>
								<li>2 Käyttäjää</li>
								<li>3 Työilmoitusta kerralla</li>
								<li>7 Työntekijäkannan hakua päivässä</li>
							</ul>

                            <p id="PricingCardDescription"> Tämä paketti on suunniteltu erityisesti keskikokoisille yrityksille, jotka tarvitsevat monipuolisempia ominaisuuksia ja joustavuutta rekrytointiprosessiinsa. Se tarjoaa kattavan ratkaisun, jonka avulla voitte hallita rekrytointeja tehokkaasti ja kasvattaa yritystänne menestyksekkäästi.</p>

						</Card>
					</Col>
					<Col span={8}>
						<Card title="Enterprise" className="pricing-card">
							<p className="price">$30/kuukaudessa</p>
							<ul>
								<li>5 Käyttäjää</li>
								<li>10 Työilmoitusta kerralla</li>
								<li>30 Työntekijäkannan hakua päivässä</li>
							</ul>

                            <p id="PricingCardDescription"> Tämä paketti on suunniteltu erityisesti suurille yrityksille, joilla on laajat rekrytointitarpeet ja jotka tarvitsevat kattavan ja skaalautuvan ratkaisun rekrytointiprosessiin. Paketti tarjoaa laajan valikoiman ominaisuuksia, jotka mahdollistavat tehokkaan rekrytointistrategian toteuttamisen ja henkilöstön hallinnoinnin.</p>

						</Card>
					</Col>
				</Row>
			</section>

			<section ref={otaYhteyttaRef} id="ota-yhteytta-main" className="section">
				<Row gutter={[16, 16]}>
					<Col xs={24} sm={16} md={16} lg={18} xl={18}>
						<section ref={otaYhteyttaRef} id="ota-yhteytta" className="section">
							<div className="contact-form">
								<h2>Ota yhteyttä</h2>
								<p>
									Liity postituslistalle ja saat ensimmäisten joukossa tiedon
									MetaWorkin julkaisusta!
								</p>
								<Switch
									checkedChildren="Työnhakija"
									unCheckedChildren="Työnantaja"
									defaultChecked={isJobSeeker}
									onChange={(checked) => setIsJobSeeker(checked)}
									style={{ marginTop: "10px" }}
								/>
								<Input
									placeholder="Sähköposti"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									style={{ marginTop: "10px" }}
								/>
								<Input.TextArea
									placeholder="Kysymyksiä, Ehdotukisa, Kritiikkiä? (Vapaaehtoinen)"
									value={additionalInfo}
									onChange={(e) => setAdditionalInfo(e.target.value)}
									style={{ marginTop: "10px" }}
                                    id="additional-info-input"
								/>
								<Button
									type="primary"
									onClick={handleJoinMailingList}
									style={{ marginTop: "10px", width: "100%" }}
								>
									Liity postituslistalle
								</Button>
							</div>
						</section>
					</Col>
					<Col xs={24} sm={8} md={8} lg={6} xl={6}>
						<div className="ota-yhteytta-footer-section">
							<h2>MetaWork 2024</h2>
							<p>
								"Innovaatioilla rakennamme tulevaisuuden työnhakua, missä
								yksilön potentiaali kohtaa mahdollisuudet"
							</p>
							<p className="disclaimer">
								*Tämä on MetaWorkin 2024 Prelaunch versio. Huomaathan, että
								ennen virallista julkaisua hinnoittelu, ominaisuudet sekä
								julkaisupäivä saattavat muuttua kehityksen aikana. Pidätämme
								oikeuden tehdä muutoksia ilman erillistä ilmoitusta. Kiitos
								mielenkiinnostasi ja tuesta matkallamme kohti parempaa
								työnhakua!
							</p>
						</div>
					</Col>
				</Row>
			</section>

			{/* Footer Section */}
			<footer>
				<Row justify="center">
					<Col span={24}>
						<p>&copy; 2024 JobHub. All rights reserved.</p>
					</Col>
				</Row>
			</footer>
		</div>
	);
}

export default LandingPage;
