import React, { useState } from "react";
import { Form, Input, Button, Select, Row, Col, message } from "antd";
import "./register.css"; // Import custom CSS for additional styling

const { Option } = Select;

const countries = [{ label: "Suomi", value: "Suomi" }];

const roles = [
	{ value: "toimitusjohtaja", label: "Toimitusjohtaja (CEO)" },
	{ value: "teknologiajohtaja", label: "Teknologiajohtaja (CTO)" },
	{ value: "talousjohtaja", label: "Talousjohtaja (CFO)" },
	{ value: "henkilostopaallikko", label: "Henkilöstöpäällikkö (HR Manager)" },
	{
		value: "markkinointipaallikko",
		label: "Markkinointipäällikkö (Marketing Manager)",
	},
	{ value: "myyntipaallikko", label: "Myyntipäällikkö (Sales Manager)" },
	{
		value: "tuotekehityspaallikko",
		label: "Tuotekehityspäällikkö (R&D Manager)",
	},
	{
		value: "asiakaspalvelupaallikko",
		label: "Asiakaspalvelupäällikkö (Customer Service Manager)",
	},
	{
		value: "viestintapaallikko",
		label: "Viestintäpäällikkö (Communications Manager)",
	},
	{ value: "tietohallintojohtaja", label: "Tietohallintojohtaja (CIO)" },
	{ value: "projektipaallikko", label: "Projektipäällikkö (Project Manager)" },
	{
		value: "tuotantopaallikko",
		label: "Tuotantopäällikkö (Production Manager)",
	},
	{ value: "laatupaallikko", label: "Laatupäällikkö (Quality Manager)" },
	{
		value: "logistiikkapaallikko",
		label: "Logistiikkapäällikkö (Logistics Manager)",
	},
	{ value: "varatoimitusjohtaja", label: "Varatoimitusjohtaja (Deputy CEO)" },
	{ value: "kehitysjohtaja", label: "Kehitysjohtaja (Development Director)" },
	{ value: "muu", label: "Muu" },
];

function CorpRegister() {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        country: "",
        city: "",
        phone: "",
        roleInCompany: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const handleSelectChange = (value, property) => {
        setFormData({ ...formData, [property]: value });
    };

	const handleSubmit = async (e) => {
		if (formData.password !== formData.confirmPassword) {
			alert("Passwords do not match!");
			return;
		}
        console.log(formData);
		try {
			const registrationResponse = await fetch(
				process.env.REACT_APP_API_ENDPOINT + "/auth/corp/register",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						email: formData.email,
						password: formData.password,
						firstName: formData.firstName,
						lastName: formData.lastName,
						country: formData.country,
						city: formData.city,
						roleInCompany: formData.roleInCompany,
					}),
				}
			);

			if (!registrationResponse.ok) {
				throw new Error(`HTTP error! status: ${registrationResponse.status}`);
			}

			const loginResponse = await fetch(
				process.env.REACT_APP_API_ENDPOINT + "/auth/login",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						email: formData.email,
						password: formData.password,
					}),
				}
			);

			if (!loginResponse.ok) {
				throw new Error(`HTTP error! status: ${loginResponse.status}`);
			}

			const loginData = await loginResponse.json();

			localStorage.clear();

			localStorage.setItem("accessToken", loginData.accessToken);
			localStorage.setItem("refreshToken", loginData.refreshToken);
			localStorage.setItem("idToken", loginData.idToken);
			localStorage.setItem("userEmail", loginData.userEmail);

			window.location.href = "/corp/joinCompany";
		} catch (error) {
			console.log(error);
			console.error("Registration error:", error);
		}
	};

    return (
        <div className="register-background">
            <div className="register-container">
                <Form onFinish={handleSubmit} className="corp-register-form" layout="vertical">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="firstName"
                                rules={[{ required: true, message: "Please input your First Name!" }]}
                            >
                                <Input placeholder="First Name" id="firstName" value={formData.firstName} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="lastName"
                                rules={[{ required: true, message: "Please input your Last Name!" }]}
                            >
                                <Input placeholder="Last Name" id="lastName" value={formData.lastName} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="country"
                                rules={[{ required: true, message: "Please select your Country!" }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a country"
                                    id="country"
                                    value={formData.country}
                                    onChange={(value) => handleSelectChange(value, "country")}
                                >
                                    {countries.map((country) => (
                                        <Option key={country.value} value={country.value}>{country.label}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="city"
                                rules={[{ required: true, message: "Please input your City!" }]}
                            >
                                <Input placeholder="City" id="city" value={formData.city} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="phone"
                        rules={[{ required: true, message: "Please input your Phone Number!" }]}
                    >
                        <Input placeholder="Phone Number" id="phone" value={formData.phone} onChange={handleChange} />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: "Please input your Email!" }]}
                    >
                        <Input type="email" placeholder="Email" id="email" value={formData.email} onChange={handleChange} />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: "Please input your Password!" }]}
                            >
                                <Input.Password placeholder="Password" id="password" value={formData.password} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="confirmPassword"
                                rules={[{ required: true, message: "Please confirm your Password!" }]}
                            >
                                <Input.Password placeholder="Confirm Password" id="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="roleInCompany"
                        rules={[{ required: true, message: "Please select your role in the company!" }]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a role"
                            id="roleInCompany"
                            value={formData.roleInCompany}
                            onChange={(value) => handleSelectChange(value, "roleInCompany")}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {roles.map((role) => (
                                <Option key={role.value} value={role.value}>{role.label}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Register
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default CorpRegister;





